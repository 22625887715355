
.ui-draggable .modal-header, .ui-draggable .modal-footer {
    cursor: -webkit-grab;
    cursor: grab;
}
.ui-draggable.ui-draggable-dragging .modal-header, .ui-draggable.ui-draggable-dragging .modal-footer {
    cursor: -webkit-grabbing;
    cursor: grabbing;
}


#sharedModal .form-control-sm {
    font-size: 1rem !important;
}


#sharedModal .form-control {
    margin-bottom: 3px;
}


#sharedModal .control-label {
    margin-bottom: 3px;
}

#sharedModal .dx-texteditor {
    margin-bottom: 2px;
    font-size: 1rem !important;
}


.modal-content .modal-footer .btn > .spinner-border {
    display: none;
}
.modal-content.loaded .modal-footer .btn .spinner-border{
    display: inline;
    display: initial;
}

#sidebarModal .modal-content {
    background-color: #003a51;
    border: none;
}
#sidebarModal .modal-header h5 {
    font-weight: 400;
}
#sidebarModal .modal-header {
    border-color: #00425d;
    color: #b2b2b2;
}
#sidebarModal .close, #sidebarModal .close:hover {
    color: #fff;
}
#sidebarModal .modal-body {
    padding: 0;
}

.error-box {
    display: none;
    justify-content: center;
    align-items: center;
    position: absolute;
    background-color: #fff;
    pointer-events: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    opacity: 0;
}

.error-box.show {
    display: flex;
    opacity: 1 !important;
}

/* Стили для дерева скважин */
.Left_fixed img {
    margin-top: -2px;
}
.node-content {
    padding: 1rem;
}
.node-content:hover {
    background-color: rgba(0,0,0,.075);
    text-decoration: none;
}
.node-content > span {
    color: #b2b2b2;
}
.node.open > .node-content .toggle:before {
    content: "\f078";
}
.node > .node-content .toggle:before {
    content: "\f054";
}
.node > .node {
    display: none;
    padding-left: 20px;
}
.node.open > .node {
    display: block;
}
/* Конец блока */


#sidebar-tree-table .treegrid-expander {
    color: rgba(255, 255, 255, 0.75);
}

#sidebar-tree-table td, #sidebar-tree-table th {
    border: 0px;
}

#sidebar-tree-table .sidebar-object-tr {
    width: 100%;
}
#sidebar-tree-table .sidebar-object-tr td {
    padding: 1rem 1rem;
}

#sidebarModal .modal-content {
    background-color: #003a51;
    border: none;
}
#sidebarModal .modal-header h5 {
    font-weight: 400;
}
#sidebarModal .modal-header {
    border-color: #00425d;
    color: #b2b2b2;
}
#sidebarModal .close, #sidebarModal .close:hover {
    color: #fff;
}
#sidebarModal .modal-body {
    padding: 0;
}

.node {
    background-color: #003a51;
    line-height: 1;
}