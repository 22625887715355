.table-report {
    font-size: .9rem !important;
}

.dx-header-row {
    background: #001d30 !important;
}

.dx-header-row > td {
    color: white !important;
}

.dx-datagrid-headers .dx-datagrid-table .dx-row > td {
    border-bottom: none;
}

.dx-header-row > td {
    vertical-align: bottom !important;
}

.dx-row:not(.dx-header-row) > td {
    vertical-align: middle !important;
}
.dx-datagrid-content {
    background: #001d30 !important;
}


/*Делает реестры высотой в пол экрана, чтобы показать скролл */
.scrollable-table-container {
    max-height: 90.5vh;
}

.table-report {
    margin-top: 5px;
}

.table-report tr th, .table-report tr td {
    border: none;
}

.table-report thead tr {
    background: #001d30;
    color: #e7e7e7;
    font-size: 0.76rem;
}

.table-report tbody tr td {
    color: #bfbfbf;
}

.table-report thead tr th.actions {
    color: rgba(0, 0, 0, 0.00);
}


.table-report tbody tr:nth-child(odd),
.table-report tbody tr.odd {
    background: #171714;
}

.table-report tbody tr:nth-child(even),
.table-report tbody tr.even {
    background: #003a51;
}

.report-section {
    padding-bottom: 10px;
    margin-bottom: 40px;
    position: relative;
    overflow-x: auto;
    width: 100%;
    max-width: 100%;
}

.report-section .tr-section th {
    padding-bottom: 0px;
}

.report-section .tr-section-from-to th {
    border-top: 0px;
    padding-top: 0px;
}

.report-section th {
    vertical-align: bottom;
    font-weight: normal;
    border-bottom: none !important;
}

.report-section td {
    padding-left: 5px;
    padding-right: 5px;
}

.report-section input:disabled, select:disabled {
    background-color: #b2b2b2;
}

.left-border {
    border-left: rgba(222,226,230,0.0902) 1px solid;
}

.preloader-box {
    display: none;
    justify-content: center;
    align-items: center;
    position: absolute;
    background-color: #fff;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    opacity: 0;
}
.preloader {
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 8px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
}

.preloader-box.show {
    display: flex;
    opacity: 1 !important;
}
